class CookieService {
  getCookie() {
    return document.cookie.split("; ").reduce((acc, item) => {
      const [name, value] = item.split("=");
      return { ...acc, [name]: value };
    }, {} as Record<string, string>);
  }
  setCookie(userInfoString: string) {
    document.cookie = 'user_info = ' + userInfoString;
  }
  deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;max-age= -1"
    }
  }
}
export default new CookieService();