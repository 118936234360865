import AuthService from "@/services/AuthService";
import TokenService from "@/services/TokenService";

export function startWorker() {
  sessionStorage.setItem("worker", "worker");
  const workerFunction = () => {
    let now = Date.now();
    setInterval(() => {
      now = Date.now();
      postMessage(now);
    }, 1000);
  };

  const blob = new Blob(["(" + workerFunction.toString() + ")()"], {type: "application/javascript",});
  const mainWorker = new Worker(URL.createObjectURL(blob));

  mainWorker.onmessage = (e) => {
    const startTime = Number(TokenService.getStartTime());
    const expireTime = Number(TokenService.getExpireTime()) * 1000;
    const endTime = startTime + expireTime;

    if (localStorage.getItem("stm_user")) {
      if (e.data - endTime >= 0 && !localStorage.getItem("non-double-refresh")) {
        mainWorker.terminate();
        AuthService.refresh();
      }
    }
  };
}
