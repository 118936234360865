// for use 
// import { WS, WsEventParsedDataType } from '@/services/WebSocketService'
// WS.open() только один раз в HomeView.vue
// WS.send( body: string | undefined )
// WS.subscribe( name: string, params: object, listener: ListenerType ) в компонентах используем только подписку и отписку
// WS.unSubscribe( name: string )
// WS.close() только один раз в HomeView.vue

import TokenService from "@/services/TokenService"
import { errNotification, readableTime } from "@/utils/BaseUtils"

const protocol: string = (window.location.protocol === "https:") ? 'wss' : 'ws';
const wsServer = (): string => {
    if (window.location.hostname === "localhost") return "10.255.250.41:5555"   //  для разработки
    else return window.location.hostname/*  + ":5555" */;     //  для развёртывания
}
const SERVER_API_URL: string = process.env.VUE_APP_API as string;
export const wsUrl = `${protocol}://${wsServer()}${SERVER_API_URL}ws`

export interface WsEventParsedDataType {
    http_code?: number,
    subscription_data?: { name: string, data: object },
    notifications_response?: { active: boolean },
    tree_states_response?: { active: boolean }
}
interface SubscriptionType {
    name: string,
    parameters: object,
    listener: ListenerType
}
type ListenerType = (
    data?: WsEventParsedDataType
) => void

const token = () => TokenService.getLocalAccessToken();

export const tokenObj = () => {
    return { "token": { "access_token": token() } }
}

export const setBodySubscription = (name: string, active: boolean, parameters: object) => {
    return JSON.stringify({
        "subscription": { name, active, parameters }
    })
}

let subscriptionList: SubscriptionType[] = [];
const subscriptionListFilter = (name: string) => {
    subscriptionList = subscriptionList.filter((v: SubscriptionType) => v.name !== name)
}

export class WS {
    static socket: WebSocket;

    static open() {
        this.socket = new WebSocket(wsUrl)

        this.socket.onopen = (e: Event) => {
            this.socket.send(JSON.stringify(tokenObj()))
            log("#9f6700", "Подключено, токен отправлен", e.timeStamp)
        }
        this.socket.onclose = (event: CloseEvent) => {
            log(...translatorCloseCode(event.code), event.timeStamp,
                event.wasClean ? " чисто" : ", нет закрывающего кадра")
            if (event.code === 1006 && token()) this.reconnect()
        }
        this.socket.onerror = (error: Event) => {
            log("red", "Error, " + wsState(this.socket.readyState),
                error.timeStamp)
        }
        this.socket.onmessage = (msg: MessageEvent) => {
            const data = JSON.parse(msg.data);

            const sendData = (name: string) => {
                const subscription = subscriptionList.filter(
                    (v: SubscriptionType) => v.name === name
                );
                if (subscription.length > 0) subscription[0].listener(data)
                else if ( data.subscription_data ) this.unSubscribe(data.subscription_data.name);
            }
            if (data.http_code) {
                if (data.http_code === 400) {   // если ошибочная подписка ...
                    for (const key of Object.keys(data)) {
                        if (key.endsWith("_response")) subscriptionListFilter(data[key].name)
                    }   //  ... убираем её из списка подписок
                } else if (data.http_code === 200) {  // если успешная подписка или отписка
                    for (const key of Object.keys(data)) {
                        if (key.endsWith("_response")) { sendData(data[key].name) }
                    }
                }
                log(...translatorHTTPCode(data),
                    msg.timeStamp, JSON.stringify(data))
            } else if (data.subscription_data) {
                sendData(data.subscription_data?.name)
                log("blue", "Данные по подписке '" + data.subscription_data.name + "': ",
                    msg.timeStamp, "подписок " + subscriptionList.length +
                    " " + JSON.stringify(data.subscription_data.data))
            }
        }
    }
    static send(body: string | undefined) {
        if (body) {  // есть тело запроса
            // статус соединения OPEN
            if (wsState(this.socket.readyState) === "OPEN") {
                this.socket.send(body)
                log("green", "Send: " + body, 0)
            } else log("#9f6700", "Send: " +
                this.socket.bufferedAmount + " " +
                wsState(this.socket.readyState) + " " + body, 0)
        } else log("red", "Пустой запрос не отправлен!", 0)
    }
    static close() {
        this.socket.close(1000, "работа закончена")
        log("green", "Закрытие соединения " + this.socket.bufferedAmount, 0)
    }
    static reconnect() {
        log("#9f6700", "Reconnect", 0)
        errNotification('Проверьте соединение.','Потеря связи!')
        setTimeout(() => WS.open(), 10000)
    }
    static subscribe(name: string, parameters: object, listener: ListenerType) {
        this.send(setBodySubscription(name, true, parameters))
        subscriptionList.push({ name, parameters, listener })
    }
    static unSubscribe(name: string) {
        if (name) {
            const subscription = subscriptionList.filter(
                (v: SubscriptionType) => v.name === name
            );
            if (subscription.length > 0) {
                this.send(setBodySubscription(name, false, {}))
                subscriptionListFilter(name)
            }
        }
    }
}

// ----------------------- для логирования -----------------------

const isLogging = false;    //  чтобы видеть логи, ставим true
const log = (
    color: string,
    value: string,
    time: number,
    plus?: string
): [string, string] => {
    const text = "ws:" + (time ? wsTime(time) : "") + " %c" + value + (plus || "")
    if (isLogging) console.log(text, "color: " + color)
    return [color, text]
}

const wsTime = (t: string | number): string => { return readableTime(t).slice(11) }

const wsState = (n: number): string => {
    switch (n) {
        case 0: return "CONNECTING" //  соединение ещё не установлено
        case 1: return "OPEN"  //  обмен данными
        case 2: return "CLOSING" //  соединение закрывается
        default: return "CLOSED" //  3 - соединение закрыто
    }
}
const translatorCloseCode = (code: number): [string, string] => {
    switch (code) {
        case 1000: return ["#9f6700", "Соединение закрыто"]
        case 1001: return ["red", "Сервер выключен"]
        case 1006: return ["red", "Соединение было потеряно"]
        case 1009: return ["red", "Сообщение слишком большое для обработки"]
        case 1011: return ["red", "Непредвиденная ошибка на сервере"]
        case 1015: return ["red", "Соединение было закрыто из-за сбоя при выполнении подтверждения TLS"]
        default: return ["red", code.toString()]
    }
}
const translatorHTTPCode = (data: { http_code: number, error: string }): [string, string] => {
    switch (data.http_code) {
        case 200: return ["blue", "Subscription response OK "]
        case 204: return ["blue", "Токен получен, соединение подтверждено "]
        case 400:
        case 401:
        case 503: return ["red", "Ошибка: "]
        default: return ["red", "Неизвестный http_code "]
    }
}