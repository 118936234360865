import ObjectStatusService from "@/services/ObjectStatusService";
import { TreeNode } from "@/models/BusinessModels";

const makeIdsArr = (el: TreeNode) => el.id

const recFuncForMakingObjs = async (id: number, parentPath: number[], objs: {ids:{object:number[][], group: number[][]},paths: {object:number[][], group: number[][]}}) => {
    const {data: children} = await ObjectStatusService.getChildren(id.toString())
    const objArr = children.filter((el: TreeNode) => el.type === "object")
    const groupArr = children.filter((el: TreeNode) => el.type === "group")
    const newPath = parentPath.concat(id)
    if(objArr.length > 0)
    {
        objs.paths.object.unshift(newPath)
        objs.ids.object.unshift(objArr.map(makeIdsArr))
    }
    if(groupArr.length > 0)
    {
        objs.paths.group.unshift(newPath)
        objs.ids.group.unshift(groupArr.map(makeIdsArr))
        for(const i in groupArr)
            await recFuncForMakingObjs(groupArr[i].id, newPath, objs)
        }
}
export const makeObjsForOpenTree = async () => {
    const ids = {
        object: [] as number[][],
        group: [] as number[][]
    }
    const paths = {
        object: [] as number[][],
        group: [] as number[][]
    }
    await recFuncForMakingObjs(0, [], {ids, paths})
    return {ids, paths}
}