import TablesService from '@/services/DataForTablesService'
import { ActionContext, ActionTree, GetterTree, Module } from 'vuex'
import { StateType } from '../configuration/storeTypes'
import { ScaleSeverityType, VocabType } from '@/models/InternalModels'
import { ScaleImpactLevels } from '@/models/AdminInterfaceModels/AdminInterfaceModels'

interface Vocabs {
    vocab_rights_entity_types: VocabType[],
    vocab_permission_types: VocabType[],
    scale_severities: ScaleSeverityType[],
    scale_impact_levels: ScaleImpactLevels[],
    vocab_delivery_channel_types: VocabType[],
    vocab_operation_types: VocabType[],
    vocab_chart_types: VocabType[]
    vocab_source_types: VocabType[],
    vocab_event_ok_generates: VocabType[],
    vocab_auth_types: VocabType[],
    vocab_http_query_types: VocabType[],
    vocab_http_query_body_types: VocabType[],
    vocab_http_responce_parts: VocabType[],
    vocab_http_auths: VocabType[],
    vocab_operators: VocabType[],
    vocab_dashboard_types: VocabType[],
    vocab_widget_types: VocabType[],
    vocab_select_value_functions: VocabType[],
    vocab_axis_sources: VocabType[],
    vocab_layout_styles: VocabType[],
    vocab_data_types: VocabType[],
    vocab_text_formats: VocabType[],
    vocab_security_connections: VocabType[],
    vocab_entity_types: VocabType[],
}

const vocabState = {
    vocabs: undefined as Vocabs | undefined
}
export type vocabModuleStateType = typeof vocabState
const vocabModuleGetters: GetterTree<vocabModuleStateType, StateType> = {
    hasVocabs: () => vocabState.vocabs,
    getRightsEntityTypes: () => vocabState.vocabs?.vocab_rights_entity_types,
    getPermissionType: () => vocabState.vocabs?.vocab_permission_types,
    getScaleSeverity: () => vocabState.vocabs?.scale_severities.sort(sortScaleSeverities),
    getScaleImpactLevels: () => vocabState.vocabs?.scale_impact_levels,
    getDeliveryChannelTypes: () => vocabState.vocabs?.vocab_delivery_channel_types,
    getVocabOperationTypes: () => vocabState.vocabs?.vocab_operation_types,
    getVocabChartTypes: () => vocabState.vocabs?.vocab_chart_types,
    getVocabSourceTypes: () => vocabState.vocabs?.vocab_source_types,
    getVocabEventOkGenerates: () => vocabState.vocabs?.vocab_event_ok_generates,
    getVocabDataTypes: () => vocabState.vocabs?.vocab_data_types,
    getVocabAuthTypes: () => vocabState.vocabs?.vocab_auth_types,
    getVocabHttpQueryTypes: () => vocabState.vocabs?.vocab_http_query_types,
    getVocabHttpQueryBodyTypes: () => vocabState.vocabs?.vocab_http_query_body_types,
    getVocabHttpResponseParts: () => vocabState.vocabs?.vocab_http_responce_parts,
    getVocabHttpAuths: () => vocabState.vocabs?.vocab_http_auths,
    getVocabOperators: () => vocabState.vocabs?.vocab_operators,
    getDashboardTypes: () => vocabState.vocabs?.vocab_dashboard_types,
    getWidgetTypes: () => vocabState.vocabs?.vocab_widget_types,
    getTextFormats: () => vocabState.vocabs?.vocab_text_formats,
    getSecurityConnections: () => vocabState.vocabs?.vocab_security_connections,
    getEntityTypes: () => vocabState.vocabs?.vocab_entity_types,
}
export type vocabModuleGettersType = typeof vocabModuleGetters & {
    hasVocabs: Vocabs,
    getRightsEntityTypes: VocabType[],
    getPermissionType: VocabType[],
    getScaleSeverity: ScaleSeverityType[],
    getScaleImpactLevels: ScaleImpactLevels[],
    getDeliveryChannelTypes: VocabType[],
    getVocabOperationTypes: VocabType[],
    getVocabChartTypes: VocabType[]
    getVocabSourceTypes: VocabType[],
    getVocabEventOkGenerates: VocabType[],
    getVocabDataTypes: VocabType[],
    getVocabAuthTypes: VocabType[],
    getVocabHttpQueryTypes: VocabType[],
    getVocabHttpQueryBodyTypes: VocabType[],
    getVocabHttpResponseParts: VocabType[],
    getVocabHttpAuths: VocabType[],
    getVocabOperators: VocabType[],
    getDashboardTypes: VocabType[],
    getWidgetTypes: VocabType[],
    getTextFormats: VocabType[],
    getSecurityConnections: VocabType[],
    getEntityTypes: VocabType[]
}
const vocabMutations = {
    setVocabs(state: vocabModuleStateType, payload: Vocabs) {
        state.vocabs = payload
    }
}
const vocabModuleActions = {
    async getVocabs(context: ActionContext<vocabModuleStateType, vocabModuleStateType>) {
        const { data } = await TablesService.getDataForFile(`/vocabs`)
        context.commit('setVocabs', data)
    }
}
export type vocabModuleActionsType = ActionTree<vocabModuleStateType, StateType> & typeof vocabModuleActions
export const vocabModule: Module<vocabModuleStateType, StateType> = {
    state: () => vocabState,
    getters: vocabModuleGetters,
    mutations: vocabMutations,
    actions: vocabModuleActions as vocabModuleActionsType
}

const sortScaleSeverities = (a: ScaleSeverityType, b: ScaleSeverityType) => Number(b.vd) - Number(a.vd);
