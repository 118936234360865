import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import ElementPlus from 'element-plus'
import './assets/styles/theming.scss'
import { startWorker } from '@/services/WorkerService'

if (window.Worker) {

    startWorker();

    const app = createApp(App)
    app.use(router)
    app.use(ElementPlus)
    app.use(store, key)
    app.mount('#app')
}