import { UserTokenType } from "@/models/BusinessModels";

class TokenService{
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem('stm_user') || '{}');
        return user?.refresh_token;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem('stm_user') || '{}');
        return user?.access_token;
    }

    getUser() {
        return JSON.parse(localStorage.getItem('stm_user') || '{}');
    }

    getStartTime() {
        return localStorage.getItem('stm_startTime');
    }

    getExpireTime() {
        return JSON.parse(localStorage.getItem('stm_user') || '{}').expires_in;
    }

    setUser(user: UserTokenType){
        localStorage.setItem('stm_user', JSON.stringify(user));
        localStorage.stm_startTime = Date.now()
        // если нужно мы также можем положить refresh_token в куки
        // user = JSON.parse(localStorage.getItem('user') || '{}');
        // document.cookie = user.refresh_token, "max-age=0"; 
        //"expires=" + user.expires_in; 
    }
    
    removeUser() {
        localStorage.removeItem('stm_user');
        localStorage.removeItem('stm_authName');
    }
}

export default new TokenService();