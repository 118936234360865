import {Router} from 'vue-router'

export const goBack = (router: Router) => {
    const localLastRoute = localStorage.getItem('oldSession')
    if(localLastRoute && localLastRoute != "/"){
      router.push({path: localLastRoute})
    } else {
      router.push( "/object-status")
    }
    localStorage.removeItem('oldSession')
}