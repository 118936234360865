import { TreeNode } from "@/models/BusinessModels";
import { ActionTree, GetterTree, Module, ActionContext } from "vuex";
import { StateType } from "@/store/configuration/storeTypes";
import { ObjsForMappingType, TreeNodeFullInfo} from "@/models/InternalModels";
import {makeObjsForOpenTree} from "@/utils/TreeModuleUtils"

const treeModuleState = {
    parentId: 0,
    selected: undefined as TreeNodeFullInfo | undefined,
    check: 0,
    treeModel: new Array<TreeNode>(),
    objsForMapping: undefined as ObjsForMappingType | undefined
}

export type treeModuleStateType = typeof treeModuleState

const treeModuleGetters: GetterTree<treeModuleStateType, StateType> = {
    getParent: (state: treeModuleStateType) => state.parentId ,
    getAllGroupsIds: (state: treeModuleStateType) => {
        if( state.objsForMapping && state.objsForMapping.ids){
            return state.objsForMapping.ids.group.reduce((prev, next) => prev.concat(next), [0])
        }
    },
    getExpiredKeys: (state: treeModuleStateType) => (type: string, id: number) => {
        if( state.objsForMapping && state.objsForMapping.ids && state.objsForMapping.paths){
            const ids = state.objsForMapping.ids[type]
            let i = 0
            while(i < ids.length){
                const hasId = ids[i].find(el => el === id)
                if(hasId)
                    break ;
                i++;
            }
            return state.objsForMapping.paths[type][i]
        }
    },
    getSelected : (state: treeModuleStateType) => state.selected
}

export type treeModuleGettersType = typeof treeModuleGetters &
    {   getParent: (nodeId: string, nodeType: string) => TreeNode[] | undefined,
        getExpiredKeys: (type: string, id: number) => number[],
        getAllGroupsIds: number[]
        getSelected: TreeNodeFullInfo | undefined
    }   
 
const treeModuleMutations = {
    setSelected(state: treeModuleStateType, node: TreeNode) {
        if (node !== state.selected)
            state.selected = node
    },
    setParentId(state: treeModuleStateType, id: number) {
        if (id !== state.parentId) {
            state.parentId = id
        }
    },
    setObjsForMapping(state: treeModuleStateType, payload: ObjsForMappingType){
        if(state.objsForMapping != payload)
            state.objsForMapping = payload
    }
}

export type treeModuleMutationsType = typeof treeModuleMutations;

const treeModuleActions = {
    async makeObjsForMapping(context: ActionContext<treeModuleStateType, treeModuleStateType>){
        context.commit("setObjsForMapping", await makeObjsForOpenTree())
    }
}

export type treeModuleActionsType = ActionTree<treeModuleStateType, StateType> & typeof treeModuleActions
export const treeModule: Module<treeModuleStateType, StateType> = {
    state: () => (treeModuleState),
    getters: treeModuleGetters,
    mutations: treeModuleMutations,
    actions: treeModuleActions as treeModuleActionsType
}