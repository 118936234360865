import { RouteRecordRaw, RouteLocationNormalizedLoaded, Router } from 'vue-router'
import { TreeNodeFullInfo } from "@/models/InternalModels"

export const ObjectStatusRoute: RouteRecordRaw = {
  path: '/object-status/:type?/:id?',
  name: 'ObjectStatus',
  components: { content: () => import(/* webpackChunkName: "objects" */ '../views/modules/ObjectStatusView.vue') },
  meta: {
    title: 'Состояние объектов',
    breadCrumb: [
      {
        text: 'Главная страница',
        to: { name: 'Home' }
      },
      {
        text: "Состояние объектов"
      },
    ],
    redirect: (route: RouteLocationNormalizedLoaded, router: Router, name: string) => {
      if (route.name == "ObjectStatus") {
        router.replace({ name, params: route.params })
      }
    },
    makeTabsPanelData: (route: RouteLocationNormalizedLoaded, node: TreeNodeFullInfo | undefined) => {
      const resultTabs: Array<{ name: string, routeName: string }> = []
      if (route.params.type && route.params.type != "") {
        if (node?.dashboards) { resultTabs.push({ name: "Приборные панели", routeName: "Schemes" }) }
        resultTabs.push({ name: "Проблемы", routeName: "Triggers" })
        resultTabs.push({ name: "Данные", routeName: "DataElements" })
        if (node?.charts) { resultTabs.push({ name: "Графики", routeName: "Charts" }) }
        if (node?.redfish) { resultTabs.push({ name: "Управление", routeName: "Mgmt" }) }
      } else {
        resultTabs.push({ name: "Проблемы", routeName: "Triggers" })
        resultTabs.push({ name: "Данные", routeName: "DataElements" })
      }
      return resultTabs
    },
  },
  children: [
    {
      path: 'dashboard',
      name: 'Schemes',
      components: { tabContent: () => import("@/components/modules/objects/tabs/TabsSchemes.vue") },
    },
    {
      path: 'triggers',
      name: 'Triggers',
      components: { tabContent: () => import("@/components/modules/objects/tabs/TabsTriggers.vue") },
      beforeEnter(to: RouteLocationNormalizedLoaded) {
        const resultRoute = { ...to }
        if (!
          ((to?.query?.page) || (to?.query?.sortBy) || (to?.query?.sortDirect))
        ) {
          if (!(to?.query?.page)) resultRoute.query.page = '1'
          if (!(to?.query?.sortBy)) resultRoute.query.sortBy = 'opened'
          if (!(to?.query?.sortDirect)) resultRoute.query.sortDirect = 'desc'
          return resultRoute
        }
      }
    },
    {
      path: 'data',
      name: 'DataElements',
      components: { tabContent: () => import("@/components/modules/objects/tabs/TabsDataElements.vue") },
      beforeEnter(to: RouteLocationNormalizedLoaded) {
        const resultRoute = { ...to }
        if (!(to?.query?.page) || !(to?.query?.sortBy) || !(to?.query?.sortDirect)) {
          if (!(to?.query?.page))
            resultRoute.query.page = "1"
          if (!(to?.query?.sortBy))
            resultRoute.query.sortBy = "data_element"
          if (!(to?.query?.sortDirect))
            resultRoute.query.sortDirect = "asc"
          return resultRoute
        }
      }
    },
    {
      path: 'charts',
      name: 'Charts',
      components: { tabContent: () => import("@/components/modules/objects/tabs/TabsCharts.vue") },
    },
    {
      path: 'mgmt',
      name: 'Mgmt',
      components: { tabContent: () => import("@/components/modules/objects/tabs/TabsMgmt.vue") },
    },
  ]
}
