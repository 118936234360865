import { TableRequestType, HistoryTableRequestType, propsForRequestExport } from "@/models/InternalModels";
import BaseService from "./BaseService";

class TablesService{
    /** TODO Используется только в двух местах. Оценить необходимость данной функции.
     *  Не удобная передача параметров(не соответствует порядку в спецификации)
     */
    public async getDataForDataElements(ids:{objectIds: number[], groupIds: number[]},
        pageNumber: number, pageSize: number,
        sortBy: string, sortDirect: string, edIds:{ed: number[], groups: number[]},
        type: "numeric"|"symbol"|"all"): Promise<TableRequestType>{
            return BaseService.post("last_values", {
                "object_ids": ids.objectIds,
                "object_group_ids": ids.groupIds,
                "ed_ids": edIds.ed,
                "ed_group_ids": edIds.groups,
                "data_type": type,
                "page_size": pageSize,
                "page_num": pageNumber,
                "sort_by": sortBy,
                "sort_direct": sortDirect
            }, undefined)
    }
    public async getDataForHistory(ids: number[], outPutType: string,
        startTime: number, endTime: number,
        pageNumber: number, pageSize: number,
        sortBy: number, sortDirect: string): Promise<HistoryTableRequestType>{
            return BaseService.post("history_values", {
                "ed_ids": ids,
                "time_start": startTime,
                "time_end": endTime,
                "for_output": outPutType,
                "page_size": pageSize,
                "page_num": pageNumber,
                "sort_by": sortBy,
                "sort_direct": sortDirect
            }, undefined)
    }
    public async getDataForFile(url: string){
        return BaseService.get(url)
    }
    public async getExport(url: string, body: propsForRequestExport){
        return BaseService.post(url, {
            "object_ids": body.objectIds,
            "object_group_ids": body.groupIds,
            "ed_ids": body.edIds,
            "ed_group_ids": body.edGroups,
            "data_type": body.type,
        }, undefined)
    }
    public async getHistoryExport(url:string, body: Record<string, unknown>){
        return BaseService.post(url, body, undefined)
    }
}

export default new TablesService()