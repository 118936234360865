import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import NotFound from '../views/NotFound.vue'
import { ObjectStatusRoute } from './object-status-router'
import { AdminInterfaceRoute } from './admin-interface-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Войти',
    },
    beforeEnter: (_, from) => {
      localStorage.setItem("oldSession", from.fullPath)
    },
    component: LoginView
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: '/',
    component: HomeView,
    meta: {
      title: 'Главная страница',
      breadCrumb: [
        {
          text: 'Главная страница',
        },
      ]
    },
    beforeEnter(to) {
      if (to.name === "Home")
        return { name: 'ObjectStatus' }
    },
    children: [
      {
        path: '',
        name: 'Home',
        components: {},
      },
      {
        ...ObjectStatusRoute
      },
      {
        path: '/service-status',
        name: 'ServiceStatus',
        components: { content: () => import(/* webpackChunkName: "about" */ '../views/modules/ServiceStatus.vue') },
        meta: {
          title: 'Состояние сервисов',
          breadCrumb: [
            {
              text: 'Главная страница',
              to: { name: 'Home' }
            },
            {
              text: "Состояние сервисов"
            },
          ],
        }
      },
      {
        path: '/scheme',
        name: 'CardAndSchemes',
        components: { content: () => import(/* webpackChunkName: "about" */ '../components/common/CardsAndSchemesComponent.vue') },
        meta: {
          title: 'Карты и схемы',
          breadCrumb: [
            {
              text: 'Главная страница',
              to: { name: 'CardAndSchemes' }
            },
            {
              text: "Карты и схемы"
            },
          ],
        },
      },
      {
        path: '/about',
        name: 'About',
        components: { content: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue') },
        meta: {
          title: 'Информация',
          breadCrumb: [
            {
              text: 'Главная страница',
              to: { name: 'About' }
            },
            {
              text: "Информация"
            },
          ],
        },
      },
      {
        path: '/my-profile',
        name: 'Profile',
        components: { content: () => import(/* webpackChunkName: "users" */ '../views/SettingView.vue') },
        meta: {
          title: 'Мой профиль',
          breadCrumb: [
            {
              text: 'Главная страница',
              to: { name: 'ObjectStatus' }
            },
            {
              text: "Настройки"
            },
          ],
        },
      },
      {
        ...AdminInterfaceRoute
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /* scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }, */
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? to.meta.title as string : "СТ.Монитор";
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('stm_user');
  if (authRequired && !loggedIn) {
    next('/login');
    // return
  } else {
    next()
    // return
  }
})

export default router
