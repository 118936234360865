import { ObjectRequestType, StateTree } from "@/models/InternalModels";
import BaseService from "./BaseService";

class ObjectStatusService {
    public async getChildren(nodeId: string): Promise<any> {
        return BaseService.get("object_tree/" + nodeId);
    }
    public async getNodeInfo(nodeId: string): Promise<ObjectRequestType> {
        return BaseService.get("object/" + nodeId);
    }
    public async getGrpNodeInfo(nodeId: string): Promise<ObjectRequestType> {
        return BaseService.get("object_group/" + nodeId);
    }
    public async getObjectTreeStates(nodeId: number, aiss: boolean): Promise<{ data: StateTree[] }> {
        return BaseService.get(`object_tree_states/${nodeId}/${aiss}`)
    }
    public async getObjectProblemBriefList(nodeId: number,): Promise<any> {
        return BaseService.get(`object_problem_list/${nodeId}`)
    }
    public async getObjectGroupProblemBriefList(nodeId: number, aiss = false): Promise<any> {
        return BaseService.get(`object_group_problem_list/${nodeId}/${aiss}`)
    }
    public async getObjectGroupState(nodeId: number, aiss: boolean) {
        return BaseService.get(`object_group_state/${nodeId}/${aiss}`)
    }
}

export default new ObjectStatusService();