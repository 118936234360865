// универсальные методы для применения в любой части проекта
import { ElNotification } from "element-plus"

export const errNotification = (err: string, title: string) => {
  ElNotification({ title: title, message: err, type: 'error', duration: 5000 })
}   // всплывающее окно об ошибке

export const notification = (
    text: string,
    title: string,
    type: "success" | "warning" | "info" | "error"
  ) => {
  ElNotification({ title: title, message: text, type: type, duration: 5000 })
}

export const readableTime = (time: string | number) => {
  if (time === 0) return "время не установлено"
  else return new Date(time).toLocaleString('ru-RU')
}

export const httpGetText = async  (url: string): Promise<string> =>  {
  let v = "";
  await fetch(url)
  .then( response => response.text())
  .then( res => v = res)
  .catch( err =>  console.error(err) )
  return v
}
export const deepClone = (array: unknown) => {
    return JSON.parse(JSON.stringify(array))
}
export const compare = (oldVal: unknown, newVal: unknown) => JSON.stringify(oldVal) === JSON.stringify(newVal) ? true : false

export const removeFromArrByVal = (arr: unknown[], val: unknown) => {
    const idx = arr.indexOf( val );
    if ( idx !== -1) arr.splice( idx, 1 )
}
export const searchByKeyVal = (
    objArr: Array<Record<string, string>>,
    key: string,
    val: string,
    key2: string
) => {
    let z;
    objArr.forEach(i => {
        if (i[key] === val) z = i[key2]
    })
    return z
}
export const searchObjById = (
    objArr: Array<Record<string, number | string>>,
    id: number
) => {
    let z = null;
    objArr.forEach(i => {
        if (i.id === id) z = i
    })
    return z
}
export const removeFromObjArrById = async (objArr: Record<string, unknown>[], id: number, idKey?: string) => {
    let index: number | undefined = undefined;
    objArr.forEach( (i,idx) => { if (i[idKey || 'id'] === id) index = idx})
    if (index !== undefined) objArr.splice( index, 1 )
}

export const decodeAndFormatSVG = async ( // для использования: import { decodeAndFormatSVG } from "@/utils/BaseUtils"
    path: string,  // например: '.container'+id , для этого должен быть <div :class="'container'+id">
    image: string, // svg закодированный base64
    width: string, // требуемая ширина изображения, например "150px"
    height: string // требуемая высота изображения
  ) => {
  const elem = getBySel(path)
  if (elem !== null) elem.textContent = ""
  elem?.insertAdjacentHTML('afterbegin', atob(image)) // вставляем декодировнный svg в целевой html-элемент
  const svgEl: HTMLElement | null = getBySel(path + ' > svg')
  if (svgEl) {
    if (!svgEl.hasAttribute('viewBox')) { // если отсутствует аттрибут viewBox
      const vbw = svgEl.getAttribute('width')?.replace(/\D/g, "")
      const vbh = svgEl.getAttribute('height')?.replace(/\D/g, "")
      svgEl.setAttribute('viewBox', "0 0 "+vbw+" "+vbh) // добавляем его
    }
    svgEl.style.width = width
    svgEl.style.height = height
  }
}
function getBySel(id: string): HTMLElement | null { return document.querySelector(id) }

export const stringToArrNumber = (str: string) => {
  const arr: number[] = []
  str?.split(',')?.forEach(i => {
    if (i !== "") arr.push(+i)
  })
  return arr
}
export const stringToBool = (str: string) => {
  return str === 'true' ? true :
    str === 'false' ? false : null
}

// для элементов форм библиотеки element plus если не работает красная рамка, ставить на событие blur или change, включается на пустое значение
export const requirator = (e: {target: {id: string, value: string}}) => {
  const elem: HTMLElement = document.getElementById(e.target.id)?.parentNode as HTMLElement
  if (e.target.value === '') elem.style.boxShadow = '0 0 0 1px var(--el-color-danger) inset'
  else elem.style.boxShadow = '0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset'
}

// поля должны быть required и иметь @blur="requirator"
export const highlightAllBlankFields = () => {
  document.querySelectorAll('.is-required input').forEach(i => i.dispatchEvent(new FocusEvent('blur')))
}

export const msToSecString = (ms: number) => ms ? (ms / 1000).toString() : ""

export const paginationParams = {
  page_size: 50,
  page_num: 1
}
export const pageSizes = [10, 25, 50, 100];
export const sortOrders = ['ascending', 'descending']

export const setVocabs = async (
  store: {
    getters: {hasVocabs: Record<string, {id: number, vd: number}[]>},
    dispatch: (type: string)=>void },
  vocabNames: string[],
  vocabs: Record<string, {id: number, vd: number}[]>
) => {
  if (!store.getters.hasVocabs) await store.dispatch("getVocabs")
  vocabNames.forEach( name => {
    vocabs[name] = store.getters.hasVocabs[name]
    switch (name) {
      case 'vocab_metadata_entity_types':
      case 'vocab_source_types': vocabs[name].sort((a,b)=> a.id - b.id); break
      case 'scale_severities': vocabs[name].sort((a,b)=> a.vd - b.vd); break
      default: break
    }
  })
}