import { RouteRecordRaw, RouteLocationNormalizedLoaded, RouteParamsRaw } from 'vue-router'

const adminBreadCrumbs = [
  { text: 'Главная страница', to: { name: 'ObjectStatus' }},
  { text: 'Администрирование' }
]
const confBreadCrumbs = [...adminBreadCrumbs, { text: 'Конфигурация мониторинга' }]

const tempBreadCrumbs = (params: RouteParamsRaw, tab?: string) => [
  ...confBreadCrumbs,
  { text: 'Шаблоны', to: { name: 'AdminTemplateList' }},
  { text: params.tName, to: {
    name: 'AdminTemplate',
    params: { tId: params.tId, tName: params.tName, tab: tab || 'general' }
  }}
]
const objBreadCrumbs = (params: RouteParamsRaw, tab?: string) => [
  ...confBreadCrumbs,
  { text: 'Объекты', to: { name: 'AdminObjectList' }},
  { text: params.name, to: {
    name: 'AdminObject',
    params: { id: params.id, name: params.name, tab: tab || 'general' }
  }}
]
const objectGroupBreadCrumbs = (params: RouteParamsRaw, tab?: string) => [
  ...confBreadCrumbs,
  { text: 'Группы объектов', to: { name: 'AdminObjectsGroupList' }},
  { text: params.ogName, to: {
    name: 'AdminObjectsGroup',
    params: { eId: params.ogId, eName: params.ogName, tab: tab || 'general' }
  }}
]
const tempTabElBreadCrumbs = (
  params: RouteParamsRaw,
  tabName: string,
  tabCompName: string
) => [
  ...tempBreadCrumbs(params),
  { text: tabName, to: {
    name: tabCompName,
    params: {tId: params.tId, tName: params.tName }
  }},
  { text: params.eName || params.eId }
]
const tempTabRuleBreadCrumbs = (
  params: RouteParamsRaw,
  tabName: string,
  tabCompName: string
) => [
  ...tempBreadCrumbs(params),
  { text: tabName, to: {
    name: tabCompName,
    params: {tId: params.tId, tName: params.tName }
  }},
  ruleBreadCrumb(params, 'AdminTempRule')
]
const objTabElBreadCrumbs = (
  params: RouteParamsRaw,
  tabName: string,
  tabCompName: string
) => [
  ...objBreadCrumbs(params),
  { text: tabName, to: {
    name: tabCompName,
    params: {id: params.id, name: params.name }
  }},
  { text: params.eName || params.eId }
]
const objectGroupTabElBreadCrumbs = (
  params: RouteParamsRaw,
  tabName: string,
  tabCompName: string
) => [
  ...objectGroupBreadCrumbs(params),
  { text: tabName, to: {
    name: tabCompName,
    params: {ogId: params.ogId, ogName: params.ogName }
  }},
  { text: params.eName || params.eId }
]
const objTabRuleBreadCrumbs = (
  params: RouteParamsRaw,
  tabName: string,
  tabCompName: string
) => [
  ...objBreadCrumbs(params),
  { text: tabName, to: {
    name: tabCompName,
    params: {id: params.id, name: params.name }
  }},
  ruleBreadCrumb(params, 'AdminObjRule')
]
const ruleDEList = (params: RouteParamsRaw, name: string) => {
  if (!params.isEDPO) {
    return [
      { text: 'ЭД-прототипы', to: { name: name } },
      { text: params.eName }
    ]
  } else return [{ text: 'ЭДПО ' + params.eName }]
}
const ruleBreadCrumb = (params: RouteParamsRaw, name: string) => {
  if (params.rId !== '0') {
    return { text: params.rName, to: {
      name: name,
      params: params
    }}
  } else return { text: 'нет данных' }
}

export const AdminInterfaceRoute: RouteRecordRaw = {
  path: '/admin/',
  name: 'Admin',
  components: { content: () => import(/* webpackChunkName: "admin" */
    '../views/AdminView.vue') },
  meta: {
    title: 'Администрирование',
    breadCrumb: [...adminBreadCrumbs]
  },
  children: [
    {
      path: 'users',
      name: 'Users',
      components: { content: () => import(/* webpackChunkName: "users" */
        '../components/modules/adminSettings/users/UserSet.vue') },
      meta: {
        title: 'Пользователи',
        breadCrumb: [...adminBreadCrumbs, { text: 'Пользователи' }]
      },
      beforeEnter(to: RouteLocationNormalizedLoaded) {
        const resultRoute = { ...to }
        if (!(to?.query?.page) || !(to?.query?.sortBy) || !(to?.query?.sortDirect)) {
          if (!(to?.query?.page)) resultRoute.query.page = "1"
          if (!(to?.query?.sortBy)) resultRoute.query.sortBy = "id"
          if (!(to?.query?.sortDirect)) resultRoute.query.sortDirect = "asc"
          return resultRoute
        }
      },
      children: [
        {
          path: ':id',
          props: true,
          name: 'UserDetails',
          components: { content: () => import(/* webpackChunkName: "users" */
            '../components/modules/adminSettings/users/UserSet.vue') },
          meta: {
            title: 'Редактирование пользователя',
            breadCrumb: (route: RouteLocationNormalizedLoaded) => [
              ...adminBreadCrumbs,
              { text: 'Пользователи', to: { name: 'Users' }},
              { text: 'Пользователь - ' + route.params.id }
            ]
          }
        }
      ]
    },{
      path: 'roles',
      name: 'Roles',
      components: { content: () => import(/* webpackChunkName: "users" */
        '../components/modules/adminSettings/roles/RoleSet.vue') },
      meta: {
        title: 'Роли',
        breadCrumb: [...adminBreadCrumbs, { text: 'Роли' }]
      },
      children: [
        {
          path: ':id',
          props: true,
          name: 'RoleDetails',
          components: { content: () => import(/* webpackChunkName: "users" */
            '../components/modules/adminSettings/roles/RoleSetDialog.vue') },
          meta: {
            title: 'Редактирование роли',
            breadCrumb: (route: RouteLocationNormalizedLoaded) => [
              ...adminBreadCrumbs,
              { text: 'Роли', to: { name: 'Roles' }},
              { text: 'Роль - ' + route.params.id }
            ]
          }
        }
      ]
    },{
      path: 'severity-scale',
      name: 'SeverityScale',
      components: { content: () => import(/* webpackChunkName: "admin" */
        '../components/modules/adminSettings/scaleSeverities/SeverityScaleList.vue') },
      meta: {
        title: 'Шкала важности и состояния',
        breadCrumb: [...adminBreadCrumbs, { text: 'Шкала важности и состояния' }]
      },
      children: [
        {
          path: ':id',
          props: true,
          name: 'SeverityScaleItem',
          components: { content: () => import(/* webpackChunkName: "admin" */
            '../components/modules/adminSettings/scaleSeverities/SeverityScaleItem.vue') },
          meta: {
            title: 'Редактирование шкалы важности и состояния',
            breadCrumb: (route: RouteLocationNormalizedLoaded) => [
              ...adminBreadCrumbs,
              { text: 'Шкала важности и состояния', to: { name: 'SeverityScale' }},
              { text:  (+route.params.id > 0 ? route.params.id :'Новый') }
            ]
          }
        }
      ]
    },{
      path: 'impact-scale',
      name: 'ImpactScale',
      components: { content: () => import(/* webpackChunkName: "admin" */
        '../components/modules/adminSettings/impactScale/ImpactScaleList.vue') },
      meta: {
        title: 'Шкала влияния',
        breadCrumb: [...adminBreadCrumbs, { text: 'Шкала влияния' }]
      },
      children: [
        {
          path: ':id',
          props: true,
          name: 'ImpactScaleItem',
          components: { content: () => import(/* webpackChunkName: "admin" */
            '../components/modules/adminSettings/impactScale/ImpactScaleItem.vue') },
          meta: {
            title: 'Редактирование шкалы влияния',
            breadCrumb: (route: RouteLocationNormalizedLoaded) => [
              ...adminBreadCrumbs,
              { text: 'Шкала влияния', to: { name: 'ImpactScale' }},
              { text:  route.params.id }
            ]
          }
        }
      ]
    },{
      path: 'delivery-channel',
      name: 'DeliveryChannel',
      components: { content: () => import(/* webpackChunkName: "admin" */
        '../components/modules/adminSettings/deliveryChannel/DeliveryChannelList.vue') },
      meta: {
        title: 'Каналы доставки',
        breadCrumb: [ ...adminBreadCrumbs, { text: 'Каналы доставки' }]
      },
      children: [
        {
          path: ':id',
          props: true,
          name: 'DeliveryChannelItem',
          components: { content: () => import(/* webpackChunkName: "admin" */
            '../components/modules/adminSettings/deliveryChannel/DeliveryChannelItem.vue') },
          meta: {
            title: 'Редактирование каналов доставки',
            breadCrumb: (route: RouteLocationNormalizedLoaded) => [...adminBreadCrumbs,
              { text: 'Каналы доставки', to: { name: 'DeliveryChannel' }},
              { text:  route.params.id }
            ]
          }
        }
      ]
    },

    { //  Журнал изменений конфигурации, общий
      path: 'changelog',
      name: 'ChangeLogGeneral',
      components: { content: () => import(/* webpackChunkName: "change_log" */
        '../components/modules/adminSettings/logs/ChangeLog.vue') },
      meta: {
        title: 'Журнал изменений конфигурации',
        breadCrumb: [...adminBreadCrumbs, { text: 'Журнал изменений конфигурации' }]
      }
    },
    { //  Журнал изменений конфигурации конкретных сущностей
      path: 'changelog/:variant/:back_route',
      name: 'ChangeLog',
      components: { content: () => import(/* webpackChunkName: "change_log" */
        '../components/modules/adminSettings/logs/ChangeLog.vue') },
      meta: {
        title: 'Журнал изменений конфигурации',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...adminBreadCrumbs,
          { text: 'Журнал изменений конфигурации' },
          { text: route.params.back_route ? (route.query.owner_name || route.query.entity_name) : '',
            to: route.params.back_route ? JSON.parse(route.params.back_route as string) : {}
          }
        ]
      }
    },

    { //  Список словарей
      path: 'vocabs',
      name: 'AdminVocabList',
      components: { content: () => import(/* webpackChunkName: "admin_vocabs" */
        '../components/modules/adminSettings/configMonitoring/VocabTable.vue') },
      meta: {
        title: 'Словари значений ЭД',
        breadCrumb: [...adminBreadCrumbs, { text: 'Словари значений ЭД' }],
        props: {
          title: "Список словарей",
          childName: "AdminVocab"
        }
      }
    },{ //  Словарь
      path: 'vocabs/:id-:name',
      name: 'AdminVocab',
      components: { content: () => import(/* webpackChunkName: "admin_vocab" */
        '../components/modules/adminSettings/configMonitoring/VocabComp.vue') },
      meta: {
        title: 'Словарь значений ЭД',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...adminBreadCrumbs,
          { text: 'Словари значений ЭД', to: { name: 'AdminVocabList' }},
          { text: route.params.name || route.params.id }
        ],
        props: { type: "словаря" }
      }
    },

    //  Конфигурация мониторинга
    { //  Список групп шаблонов
      path: 'templates_groups',
      name: 'AdminTemplatesGroupList',
      components: { content: () => import(/* webpackChunkName: "admin_templates_groups" */
        '../components/modules/adminSettings/configMonitoring/TemplatesGroupTable.vue') },
      meta: {
        title: 'Группы шаблонов',
        breadCrumb: [...confBreadCrumbs, { text: 'Группы шаблонов' }]
      }
    },{ //  Группа шаблонов
      path: 'templates_groups/:eId-:eName',
      name: 'AdminTemplatesGroup',
      components: { content: () => import(/* webpackChunkName: "admin_templates_group" */
        '../components/modules/adminSettings/configMonitoring/TemplatesGroupComp.vue') },
      meta: {
        title: 'Конфигурация группы',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...confBreadCrumbs,
          { text: 'Группы шаблонов', to: { name: 'AdminTemplatesGroupList' }},
          { text: route.params.eName || route.params.eId }
        ],
        props: { type: "группы шаблонов" }
      }
    },{ //  Список шаблонов
      path: 'templates',
      name: 'AdminTemplateList',
      components: { content: () => import(/* webpackChunkName: "admin_templates" */
        '../components/modules/adminSettings/configMonitoring/TemplateTable.vue') },
      meta: {
        title: 'Шаблоны',
        breadCrumb: [...confBreadCrumbs, { text: 'Шаблоны' }]
      }
    },{ //  Шаблон
      path: 'templates/:tId-:tName/:tab?',
      name: 'AdminTemplate',
      components: { content: () => import(/* webpackChunkName: "admin_template" */
        '../components/modules/adminSettings/configMonitoring/TemplateComp.vue') },
      meta: {
        title: 'Конфигурация шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...confBreadCrumbs,
          { text: 'Шаблоны', to: { name: 'AdminTemplateList' }},
          { text: route.params.tName || route.params.tId }
        ],
        props: { type: "шаблона" }
      }
    },{ //  Список элементов данных шаблона
      path: 'templates/:tId-:tName/data_elements',
      name: 'AdminTempDEList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_de_list" */
        '../components/modules/adminSettings/configMonitoring/DETable.vue') },
      meta: {
        title: 'Конфигурация элементов данных шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Элементы данных' }
        ]
      }
    },{ //  Элемент данных шаблона
      path: 'templates/:tId-:tName/data_elements/:eId-:eName/:tab?',
      name: 'AdminTempDE',
      components: { content: () => import(/* webpackChunkName: "admin_temp_de" */
        '../components/modules/adminSettings/configMonitoring/DEComp.vue') },
      meta: {
        title: 'Конфигурация ЭД шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabElBreadCrumbs(route.params, 'Элементы данных', 'AdminTempDEList')
        ]
      }
    },{ //  Список ГЭД шаблона
      path: 'templates/:tId-:tName/data_elements_groups',
      name: 'AdminTempDEGList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_deg_list" */
        '../components/modules/adminSettings/configMonitoring/DEGTable.vue') },
      meta: {
        title: 'Конфигурация групп ЭД шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Группы ЭД' }
        ]
      }
    },{ //  ГЭД шаблона
      path: 'templates/:tId-:tName/data_elements_groups/:eId-:eName',
      name: 'AdminTempDEG',
      components: { content: () => import(/* webpackChunkName: "admin_temp_deg" */
        '../components/modules/adminSettings/configMonitoring/DEGComp.vue') },
      meta: {
        title: 'Конфигурация ГЭД шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabElBreadCrumbs(route.params, 'Группы ЭД', 'AdminTempDEGList')
        ]
      }
    },{ //  Список триггеров шаблона
      path: 'templates/:tId-:tName/triggers',
      name: 'AdminTempTriggerList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_triggers" */
        '../components/modules/adminSettings/configMonitoring/TriggerTable.vue') },
      meta: {
        title: 'Конфигурация триггеров шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Триггеры' }
        ]
      }
    },{ //  Триггер шаблона
      path: 'templates/:tId-:tName/triggers/:eId-:eName/:tab?',
      name: 'AdminTempTrigger',
      components: { content: () => import(/* webpackChunkName: "admin_temp_trigger" */
        '../components/modules/adminSettings/configMonitoring/TriggerComp.vue') },
      meta: {
        title: 'Конфигурация триггера шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabElBreadCrumbs(route.params, 'Триггеры', 'AdminTempTriggerList')
        ],
        props: { type: "триггера" }
      }
    },{ //  Список графиков шаблона
      path: 'templates/:tId-:tName/charts',
      name: 'AdminTempChartList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_charts" */
        '../components/modules/adminSettings/configMonitoring/ChartsTable.vue') },
      meta: {
        title: 'Конфигурация графиков шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Графики' }
        ]
      }
    },{ //  График шаблона
      path: 'templates/:tId-:tName/charts/:eId-:eName/:tab?',
      name: 'AdminTempChart',
      components: { content: () => import(/* webpackChunkName: "admin_temp_chart" */
        '../components/modules/adminSettings/configMonitoring/ChartComp.vue') },
      meta: {
        title: 'Конфигурация графика шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabElBreadCrumbs(route.params, 'Графики', 'AdminTempChartList')
        ],
        props: { type: "графика" }
      }
    },{ //  Список дашбордов шаблона
      path: 'templates/:tId-:tName/dashboards',
      name: 'AdminTempDashboardList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_dashboards" */
        '../components/modules/adminSettings/configMonitoring/DashboardsTable.vue') },
      meta: {
        title: 'Конфигурация дашбордов шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Дашборды' }
        ]
      }
    },{ //  Дашборд шаблона
      path: 'templates/:tId-:tName/dashboards/:eId-:eName',
      name: 'AdminTempDashboard',
      components: { content: () => import(/* webpackChunkName: "admin_temp_dashboard" */
        '../components/modules/adminSettings/configMonitoring/DashboardComp.vue') },
      meta: {
        title: 'Конфигурация дашборда шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabElBreadCrumbs(route.params, 'Дашборды', 'AdminTempDashboardList')
        ],
        props: { type: "дашборда" }
      }
    },{ //  Список правил обнаружения шаблона
      path: 'templates/:tId-:tName/discovery_rules',
      name: 'AdminTempRuleList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rules" */
        '../components/modules/adminSettings/configMonitoring/RulesTable.vue') },
      meta: {
        title: 'Конфигурация правил обнаружения шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Правила обнаружения' }
        ]
      }
    },{ //  Правило обнаружения шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/:tab?',
      name: 'AdminTempRule',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule" */
        '../components/modules/adminSettings/configMonitoring/RuleComp.vue') },
      meta: {
        title: 'Конфигурация правила обнаружения шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempBreadCrumbs(route.params),
          { text: 'Правила обнаружения', to: {
            name: 'AdminTempRuleList',
            params: {tId: route.params.tId, tName: route.params.tName }
          }},
          { text: route.params.rName }
        ],
        props: { type: "правила обнаружения" }
      }
    },{ //  Список ЭД ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/data_elements',
      name: 'AdminTempRuleDEList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_des" */
        '../components/modules/adminSettings/configMonitoring/DETable.vue') },
      meta: {
        title: 'Элементы данных ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          { text: 'ЭД-прототипы' }
        ]
      }
    },{ //  Элемент данных ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/data_elements/:eId-:eName-:isEDPO?/:tab?',
      name: 'AdminTempRuleDE',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_de" */
        '../components/modules/adminSettings/configMonitoring/DEComp.vue') },
      meta: {
        title: 'Элемент данных ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          ...ruleDEList(route.params, 'AdminTempRuleDEList')
        ]
      }
    },{ //  Список триггеров ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/triggers',
      name: 'AdminTempRuleTriggerList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_triggers" */
        '../components/modules/adminSettings/configMonitoring/TriggerTable.vue') },
      meta: {
        title: 'Конфигурация триггеров ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          { text: 'Триггеры-прототипы' }
        ]
      }
    },{ //  Триггер ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/triggers/:eId-:eName/:tab?',
      name: 'AdminTempRuleTrigger',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_trigger" */
        '../components/modules/adminSettings/configMonitoring/TriggerComp.vue') },
      meta: {
        title: 'Конфигурация триггера ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          { text: 'Триггеры-прототипы', to: { name: 'AdminTempRuleTriggerList' } },
          { text: route.params.eName }
        ],
        props: { type: "триггера" }
      }
    },{ //  Список графиков ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/charts',
      name: 'AdminTempRuleChartList',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_charts" */
        '../components/modules/adminSettings/configMonitoring/ChartsTable.vue') },
      meta: {
        title: 'Конфигурация графиков ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          { text: 'Графики-прототипы' }
        ]
      }
    },{ //  График ПО шаблона
      path: 'templates/:tId-:tName/discovery_rules/:rId-:rName/charts/:eId-:eName/:tab?',
      name: 'AdminTempRuleChart',
      components: { content: () => import(/* webpackChunkName: "admin_temp_rule_chart" */
        '../components/modules/adminSettings/configMonitoring/ChartComp.vue') },
      meta: {
        title: 'Конфигурация графика ПО шаблона',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...tempTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminTempRuleList'),
          { text: 'Графики-прототипы', to: { name: 'AdminTempRuleChartList' } },
          { text: route.params.eName }
        ],
        props: { type: "графика" }
      }
    },

    { //  Список объектов
      path: 'objects',
      name: 'AdminObjectList',
      components: { content: () => import(/* webpackChunkName: "admin_objects" */
        '../components/modules/adminSettings/configMonitoring/ObjectsTable.vue') },
      meta: {
        title: 'Объекты',
        breadCrumb: [...confBreadCrumbs, { text: 'Объекты' }]
      }
    },{ //  Объект
      path: 'objects/:id-:name/:tab?',
      name: 'AdminObject',
      components: { content: () => import(/* webpackChunkName: "admin_object" */
        '../components/modules/adminSettings/configMonitoring/ObjectComp.vue') },
      meta: {
        title: 'Конфигурация объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...confBreadCrumbs,
          { text: 'Объекты', to: { name: 'AdminObjectList' }},
          { text: route.params.name || route.params.id }
        ]
      }
    },{ // Настройка шаблона объекта , TODO поместить компонент во вкладку
      path: 'objects/:id-:name/templates/:eId-:eName',
      name: 'AdminObjectTempDel',
      components: { content: () => import(/* webpackChunkName: "admin_object" */
        '../components/modules/adminSettings/configMonitoring/ConfirmDel.vue') },
      meta: {
        title: 'Настройка шаблона объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params, 'templates'),
          { text: 'Шаблон: ' + (route.params.eName || route.params.eId) }
        ]
      }
    },{ //  Список элементов данных объекта
      path: 'objects/:id-:name/data_elements',
      name: 'AdminObjDEList',
      components: { content: () => import(/* webpackChunkName: "admin_data_elements" */
        '../components/modules/adminSettings/configMonitoring/DETable.vue') },
      meta: {
        title: 'Конфигурация элементов данных объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Элементы данных' }
        ]
      }
    },{ //  Элемент данных объекта
      path: 'objects/:id-:name/data_elements/:eId-:eName/:tab?',
      name: 'AdminObjDE',
      components: { content: () => import(/* webpackChunkName: "admin_data_elements" */
        '../components/modules/adminSettings/configMonitoring/DEComp.vue') },
      meta: {
        title: 'Конфигурация элемента данных объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabElBreadCrumbs(route.params, 'Элементы данных', 'AdminObjDEList')
        ]
      }
    },{ //  Список ГЭД объекта
      path: 'objects/:id-:name/data_elements_groups',
      name: 'AdminObjDEGList',
      components: { content: () => import(/* webpackChunkName: "admin_de_groups" */
        '../components/modules/adminSettings/configMonitoring/DEGTable.vue') },
      meta: {
        title: 'Конфигурация ГЭД объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Группы элементов данных' }
        ]
      }
    },{ //  ГЭД объекта
      path: 'objects/:id-:name/data_elements_groups/:eId-:eName',
      name: 'AdminObjDEG',
      components: { content: () => import(/* webpackChunkName: "admin_deg" */
        '../components/modules/adminSettings/configMonitoring/DEGComp.vue') },
      meta: {
        title: 'Конфигурация ГЭД объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabElBreadCrumbs(route.params, 'Группы элементов данных', 'AdminObjDEGList')
        ],
        props: { type: "группы элементов данных" }
      }
    },{ //  Список триггеров объекта
      path: 'objects/:id-:name/triggers',
      name: 'AdminObjTriggerList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_triggers" */
        '../components/modules/adminSettings/configMonitoring/TriggerTable.vue') },
      meta: {
        title: 'Конфигурация триггеров объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Триггеры' }
        ]
      }
    },{ //  Триггер объекта
      path: 'objects/:id-:name/triggers/:eId-:eName/:tab?',
      name: 'AdminObjTrigger',
      components: { content: () => import(/* webpackChunkName: "admin_obj_trigger" */
        '../components/modules/adminSettings/configMonitoring/TriggerComp.vue') },
      meta: {
        title: 'Конфигурация триггера объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabElBreadCrumbs(route.params, 'Триггеры', 'AdminObjTriggerList')
        ],
        props: { type: "триггера" }
      }
    },{ //  Список графиков объекта
      path: 'objects/:id-:name/charts',
      name: 'AdminObjChartList',
      components: { content: () => import(/* webpackChunkName: "admin_charts" */
        '../components/modules/adminSettings/configMonitoring/ChartsTable.vue') },
      meta: {
        title: 'Конфигурация графиков объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Графики' }
        ]
      }
    },{ //  График объекта
      path: 'objects/:id-:name/charts/:eId-:eName/:tab?',
      name: 'AdminObjChart',
      components: { content: () => import(/* webpackChunkName: "admin_chart" */
        '../components/modules/adminSettings/configMonitoring/ChartComp.vue') },
      meta: {
        title: 'Конфигурация графика объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabElBreadCrumbs(route.params, 'Графики', 'AdminObjChartList')
        ],
        props: { type: "графика" }
      }
    },{ //  Список дашбордов объекта
      path: 'objects/:id-:name/dashboards',
      name: 'AdminObjDashboardList',
      components: { content: () => import(/* webpackChunkName: "admin_dashboards" */
        '../components/modules/adminSettings/configMonitoring/DashboardsTable.vue') },
      meta: {
        title: 'Конфигурация дашбордов объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Дашборды' }
        ]
      }
    },{ //  Дашборд объекта
      path: 'objects/:id-:name/dashboards/:eId-:eName',
      name: 'AdminObjDashboard',
      components: { content: () => import(/* webpackChunkName: "admin_dashboard" */
        '../components/modules/adminSettings/configMonitoring/DashboardComp.vue') },
      meta: {
        title: 'Конфигурация дашборда объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabElBreadCrumbs(route.params, 'Дашборды', 'AdminObjDashboardList')
        ],
        props: { type: "дашборда" }
      }
    },{ //  Список правил обнаружения объекта
      path: 'objects/:id-:name/discovery_rules',
      name: 'AdminObjRuleList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rules" */
        '../components/modules/adminSettings/configMonitoring/RulesTable.vue') },
      meta: {
        title: 'Конфигурация правил обнаружения объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Правила обнаружения' }
        ]
      }
    },{ //  Правило обнаружения объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/:tab?',
      name: 'AdminObjRule',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule" */
        '../components/modules/adminSettings/configMonitoring/RuleComp.vue') },
      meta: {
        title: 'Конфигурация правила обнаружения объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objBreadCrumbs(route.params),
          { text: 'Правила обнаружения', to: {
            name: 'AdminObjRuleList',
            params: {id: route.params.id, name: route.params.name }
          }},
          { text: route.params.rName }
        ],
        props: { type: "правила обнаружения" }
      }
    },{ //  Список ЭД ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/data_elements',
      name: 'AdminObjRuleDEList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_des" */
        '../components/modules/adminSettings/configMonitoring/DETable.vue') },
      meta: {
        title: 'Элементы данных ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          { text: 'ЭД-прототипы' }
        ]
      }
    },{ //  Элемент данных ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/data_elements/:eId-:eName-:isEDPO?/:tab?',
      name: 'AdminObjRuleDE',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_de" */
        '../components/modules/adminSettings/configMonitoring/DEComp.vue') },
      meta: {
        title: 'Элемент данных ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          ...ruleDEList(route.params, 'AdminObjRuleDEList')
        ]
      }
    },{ //  Список триггеров ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/triggers',
      name: 'AdminObjRuleTriggerList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_triggers" */
        '../components/modules/adminSettings/configMonitoring/TriggerTable.vue') },
      meta: {
        title: 'Конфигурация триггеров ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          { text: 'Триггеры-прототипы' }
        ]
      }
    },{ //  Триггер ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/triggers/:eId-:eName/:tab?',
      name: 'AdminObjRuleTrigger',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_trigger" */
        '../components/modules/adminSettings/configMonitoring/TriggerComp.vue') },
      meta: {
        title: 'Конфигурация триггера ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          { text: 'Триггеры-прототипы', to: { name: 'AdminObjRuleTriggerList' } },
          { text: route.params.eName }
        ],
        props: { type: "триггера" }
      }
    },{ //  Список графиков ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/charts',
      name: 'AdminObjRuleChartList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_charts" */
        '../components/modules/adminSettings/configMonitoring/ChartsTable.vue') },
      meta: {
        title: 'Конфигурация графиков ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          { text: 'Графики-прототипы' }
        ]
      }
    },{ //  График ПО объекта
      path: 'objects/:id-:name/discovery_rules/:rId-:rName/charts/:eId-:eName/:tab?',
      name: 'AdminObjRuleChart',
      components: { content: () => import(/* webpackChunkName: "admin_obj_rule_chart" */
        '../components/modules/adminSettings/configMonitoring/ChartComp.vue') },
      meta: {
        title: 'Конфигурация графика ПО объекта',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objTabRuleBreadCrumbs(route.params, 'Правила обнаружения', 'AdminObjRuleList'),
          { text: 'Графики-прототипы', to: { name: 'AdminObjRuleChartList' } },
          { text: route.params.eName }
        ],
        props: { type: "графика" }
      }
    },

    { //  Список групп объектов
      path: 'objects_groups',
      name: 'AdminObjectsGroupList',
      components: { content: () => import(/* webpackChunkName: "admin_objects_groups" */
        '../components/modules/adminSettings/configMonitoring/ObjectsGroupTable.vue') },
      meta: {
        title: 'Группы объектов',
        breadCrumb: [...confBreadCrumbs, { text: 'Группы объектов' }]
      }
    },{ //  Группа объектов
      path: 'objects_groups/:eId-:eName',
      name: 'AdminObjectsGroup',
      components: { content: () => import(/* webpackChunkName: "admin_objects_group" */
        '../components/modules/adminSettings/configMonitoring/ObjectsGroupComp.vue') },
      meta: {
        title: 'Конфигурация группы',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...confBreadCrumbs,
          { text: 'Группы объектов', to: { name: 'AdminObjectsGroupList' }},
          { text: route.params.eName || route.params.eId }
        ],
        props: { type: "группы" }
      }

    },{ //  Список элементов данных группы объектов
      path: 'objects_groups/:ogId-:ogName/data_elements',
      name: 'AdminObjectGroupDEList',
      components: { content: () => import(/* webpackChunkName: "admin_data_elements" */
        '../components/modules/adminSettings/configMonitoring/DETable.vue') },
      meta: {
        title: 'Конфигурация элементов данных группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupBreadCrumbs(route.params),
          { text: 'Элементы данных' }
        ]
      }
    },{ //  Элемент данных группы объектов
      path: 'objects_groups/:ogId-:ogName/data_elements/:eId-:eName/:tab?',
      name: 'AdminObjectGroupDE',
      components: { content: () => import(/* webpackChunkName: "admin_data_elements" */
        '../components/modules/adminSettings/configMonitoring/DEComp.vue') },
      meta: {
        title: 'Конфигурация элемента данных группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupTabElBreadCrumbs(route.params, 'Элементы данных', 'AdminObjectGroupDEList')
        ]
      }
    },{ //  Список ГЭД группы объектов
      path: 'objects_groups/:ogId-:ogName/data_elements_groups',
      name: 'AdminObjectGroupDEGList',
      components: { content: () => import(/* webpackChunkName: "admin_de_groups" */
        '../components/modules/adminSettings/configMonitoring/DEGTable.vue') },
      meta: {
        title: 'Конфигурация ГЭД группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupBreadCrumbs(route.params),
          { text: 'Группы элементов данных' }
        ]
      }
    },{ //  ГЭД группы объектов
      path: 'objects_groups/:ogId-:ogName/data_elements_groups/:eId-:eName',
      name: 'AdminObjectGroupDEG',
      components: { content: () => import(/* webpackChunkName: "admin_deg" */
        '../components/modules/adminSettings/configMonitoring/DEGComp.vue') },
      meta: {
        title: 'Конфигурация ГЭД группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupTabElBreadCrumbs(route.params, 'Группы элементов данных', 'AdminObjectGroupDEGList')
        ],
        props: { type: "группы элементов данных" }
      }
    },{ //  Список триггеров группы объектов
      path: 'objects_groups/:ogId-:ogName/triggers',
      name: 'AdminObjectGroupTriggerList',
      components: { content: () => import(/* webpackChunkName: "admin_obj_triggers" */
        '../components/modules/adminSettings/configMonitoring/TriggerTable.vue') },
      meta: {
        title: 'Конфигурация триггеров группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupBreadCrumbs(route.params),
          { text: 'Триггеры' }
        ]
      }
    },{ //  Триггер группы объектов
      path: 'objects_groups/:ogId-:ogName/triggers/:eId-:eName/:tab?',
      name: 'AdminObjectGroupTrigger',
      components: { content: () => import(/* webpackChunkName: "admin_obj_trigger" */
        '../components/modules/adminSettings/configMonitoring/TriggerComp.vue') },
      meta: {
        title: 'Конфигурация триггера группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupTabElBreadCrumbs(route.params, 'Триггеры', 'AdminObjectGroupTriggerList')
        ],
        props: { type: "триггера" }
      }
    },{ //  Список графиков группы объектов
      path: 'objects_groups/:ogId-:ogName/charts',
      name: 'AdminObjectGroupChartList',
      components: { content: () => import(/* webpackChunkName: "admin_charts" */
        '../components/modules/adminSettings/configMonitoring/ChartsTable.vue') },
      meta: {
        title: 'Конфигурация графиков группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupBreadCrumbs(route.params),
          { text: 'Графики' }
        ]
      }
    },{ //  График группы объектов
      path: 'objects_groups/:ogId-:ogName/charts/:eId-:eName/:tab?',
      name: 'AdminObjectGroupChart',
      components: { content: () => import(/* webpackChunkName: "admin_chart" */
        '../components/modules/adminSettings/configMonitoring/ChartComp.vue') },
      meta: {
        title: 'Конфигурация графика группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupTabElBreadCrumbs(route.params, 'Графики', 'AdminObjectGroupChartList')
        ],
        props: { type: "графика" }
      }
    },{ //  Список дашбордов группы объектов
      path: 'objects_groups/:ogId-:ogName/dashboards',
      name: 'AdminObjectGroupDashboardList',
      components: { content: () => import(/* webpackChunkName: "admin_dashboards" */
        '../components/modules/adminSettings/configMonitoring/DashboardsTable.vue') },
      meta: {
        title: 'Конфигурация дашбордов группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupBreadCrumbs(route.params),
          { text: 'Дашборды' }
        ]
      }
    },{ //  Дашборд группы объектов
      path: 'objects_groups/:ogId-:ogName/dashboards/:eId-:eName',
      name: 'AdminObjectGroupDashboard',
      components: { content: () => import(/* webpackChunkName: "admin_dashboard" */
        '../components/modules/adminSettings/configMonitoring/DashboardComp.vue') },
      meta: {
        title: 'Конфигурация дашборда группы объектов',
        breadCrumb: (route: RouteLocationNormalizedLoaded) => [
          ...objectGroupTabElBreadCrumbs(route.params, 'Дашборды', 'AdminObjectGroupDashboardList')
        ],
        props: { type: "дашборда" }
      }
    }
  ]
}
