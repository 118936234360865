import AuthService from '@/services/AuthService'
import TokenService from '@/services/TokenService'
import { UserTokenType, UserType } from '@/models/BusinessModels'
import { ActionContext, Module, GetterTree, ActionTree } from 'vuex';
import { StateType } from "@/store/configuration/storeTypes";

const user = TokenService.getUser();
const initialState = user 
                    ? {loggedIn: true, user, startTime:user?.expires_in } 
                    : {loggedIn: false, user: null, startTime:null};

export type authModuleStateType = typeof initialState
type ActionContextType = ActionContext<authModuleStateType, StateType>


const authModuleGetters : GetterTree<authModuleStateType, StateType> = {
    getLoggedIn: (state: authModuleStateType) => state.loggedIn,
    getExpires:  (state: authModuleStateType) => state?.user?.expires_in,
    getRefresh:  (state: authModuleStateType) => state?.user?.refresh_token,
}
export type initialStateGettersType  = typeof authModuleGetters & {
    getLoggedIn : boolean,
    getExpires: number,
    getRefresh: string,
}


const authModuleMutations = {
    loginRequest(state:authModuleStateType, username: string) {
        state.loggedIn = true;
        localStorage.setItem ('stm_authName', username);
    },
    loginSuccess(state: authModuleStateType, user:UserTokenType) {
        state.loggedIn = true;
        state.user = user;
    },
    logout(state: authModuleStateType){
        state.loggedIn = false;
        state.user = null;
    }
}
export type authModuleMutationsType = typeof authModuleMutations;


const authModuleActions = {
    login(context: ActionContextType, user:UserType) {
        context.commit('loginRequest',  user.username);
    },

    logout(context: ActionContextType){
        AuthService.logout();
        context.commit('logout');
    },

    updateAccessRefreshTokens(context: ActionContextType, user:UserType){
        context.commit('loginSuccess', user);
    },
}
export type authModuleActionsType = ActionTree<authModuleStateType, StateType> & typeof authModuleActions


export const authModule: Module <authModuleStateType, StateType> = {
    state: initialState,
    getters: authModuleGetters,
    mutations: authModuleMutations, 
    actions: authModuleActions,
}