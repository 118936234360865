import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore} from 'vuex'
import { StateType, StoreType } from '@/store/configuration/storeTypes'
import {treeModule} from './modules/treeModule'
import { authModule } from "./modules/authModule"
import { vocabModule } from './modules/vocabModule'

export const key: InjectionKey<StoreType> = Symbol()

export const store = createStore<StateType>({
  modules:{
    treeModule, 
    authModule,
    vocabModule
  }
})

export const useStore: () => StoreType = function(){
  return baseUseStore(key)
}
