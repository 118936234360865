import TokenService from "./TokenService";
import axios, { AxiosInstance } from "axios";
import { UserType } from "@/models/BusinessModels";
import { store } from "@/store";
import router from "@/router";
import { authHeader } from "./AuthHeader";
import BaseService from "./BaseService";
import { dataUserInfo } from "@/models/InternalModels";
import CookieService from "./CookieService";
import { startWorker } from "@/services/WorkerService";

class AuthService {
  SERVER_API_URL: string = process.env.VUE_APP_API as string;
  instance: AxiosInstance = axios.create({
    baseURL: this.SERVER_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 120000,
  });
  deleteUserAndCookies() {
    TokenService.removeUser();
    CookieService.deleteAllCookies();
    router.push({ name: "Login" });
    localStorage.removeItem("refresh-send");
  }

  login(user: UserType) {
    return this.instance
      .post("login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);
          if (!sessionStorage.getItem("worker")) {
            startWorker();
          }
        }
        return response.data;
      });
  }
  logout() {
    const headers = authHeader();
    this.instance.get("logout", {
      headers: {
        Authorization: headers,
      },
    });
    this.deleteUserAndCookies();
    localStorage.removeItem("stm_startTime");
  }
  userInfo() {
    return BaseService.get("user_info").then(
      (response: { data: dataUserInfo }) => {
        const userInfoString = JSON.stringify(response.data);
        CookieService.setCookie(userInfoString);
      }
    );
  }
  refresh() {
    if (TokenService.getLocalAccessToken()) {
      const flag = "refreshOnly";
      localStorage.setItem("refresh-send", flag);

      if (!localStorage.getItem("non-double-refresh")) {
        localStorage.setItem("non-double-refresh", "NDR");
        return this.instance
          .post("refresh", {
            refresh_token: store.getters.getRefresh,
          })
          .then((response) => {
            if (response.data.access_token) {
              store.dispatch("updateAccessRefreshTokens", response.data);
              TokenService.setUser(response.data);
              startWorker();
            } else {
              this.deleteUserAndCookies();
            }
          })
          .catch(() => {
            this.deleteUserAndCookies();
          })
          .finally(() => {
            localStorage.removeItem("refresh-send");
            localStorage.removeItem("non-double-refresh");
          });
      }
    }
  }
}

export default new AuthService();
