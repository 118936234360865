import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { errNotification } from "@/utils/BaseUtils";
import { authHeader } from "./AuthHeader";
import TokenService from "./TokenService";
import AuthService from "./AuthService";

class BaseService {
  SERVER_API_URL: string = process.env.VUE_APP_API as string;
  instance: AxiosInstance = axios.create({
    baseURL: this.SERVER_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 120000,
  });

  setRequestHeader(request: any) {
    const token = TokenService.getLocalAccessToken();
    if (request && request.headers && token) {
      request.headers["Authorization"] = authHeader();
    }
  }

  sleepFunc = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  };

  constructor() {
    this.instance.interceptors.request.use(
      async (request) => {
        if (!localStorage.getItem("refresh-send")) {
          this.setRequestHeader(request);
          return request;
        } else {
          let flagItem = localStorage.getItem("refresh-send");
          while (flagItem && flagItem == "refreshOnly") {
            flagItem = localStorage.getItem("refresh-send");
            await this.sleepFunc();
          }
          this.setRequestHeader(request);
          return request;
        }
      },
      async (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        /** TODO заготовка перехвата ответов от сервера, например 204 - показать "записи не найдены" */
        return response;
      },
      async (error) => {
        if (error.response) {
          if (error.response.status == 401) {
            AuthService.deleteUserAndCookies();
          } else if (error.response.status == 400) {
            errNotification(error.response.data.error, "Ошибка запроса");
          } else if (error.response.status == 500) {
            errNotification(error.response.data.error, "Ошибка ответа сервера");
          }
          if (error.code == "ECONNABORTED") {
            errNotification(error.response.data.error, "Превышено время ожидания ответа");
          }
        }
        return Promise.reject(error);
      }
    );
  }

  public get(url: string, params?: URLSearchParams): any {
    const headers = {};
    return this.instance.get(url, {
      headers,
      /* withCredentials: true, */ params: params,
    });
  }

  public post(url: string, body: any, headers: any): any {
    return this.instance.post(url, body, headers);
  }

  public patch(url: string, body: unknown, headers?: AxiosRequestConfig<unknown>): Promise<unknown> {
    return this.instance.patch(url, body, headers);
  }
}

export default new BaseService();
